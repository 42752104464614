import React from "react"
import styled from "styled-components"

import { FAQText, CollapsibleList } from "../../components"
import { FAQListItemsTextProps } from "../../components/FAQ"

const data: {
  content: {
    id: number
    numbered?: boolean
    title: string
    text: FAQListItemsTextProps[]
  }[]
} = {
  content: [
    {
      id: 1,
      title: "Introduction",
      numbered: true,
      text: [
        {
          id: 1,
          description:
            "Workerslife is commitment to high levels of ethical behaviour, integrity and fairness in all business dealings",
        },
        {
          id: 2,
          description:
            "Workerslife does not only aim to gain financial returns, however equally building and retaining the trust that customers place in its ability to manage their risk and to provide sound financial products and services. The building and protection of Workerslife’s reputation is therefore of the utmost importance.",
        },
        {
          id: 3,
          description:
            "A conflict of interest situation arises when an employee experiences a situation in which their interest or the interests of Workerslife, or one of its associates in the exercise of its activities, are directly or indirectly in competition with, or may significantly prejudice a customer’s interests.",
        },
        {
          id: 4,
          description:
            "Conflicting interests are generally characterised by a compromise or prevention of efficient, honest or objective work performance, because of employees maintaining inappropriate personal interests, either directly or through their close relationships.",
        },
        {
          id: 5,
          description:
            "A conflicting interest may include but are not limited to a real or perceived financial gain resulting from recommendations to customers that may prejudice an outcome of service delivery or transaction that may not best serve the interests of such customer.",
        },
        {
          id: 6,
          description:
            "Not all conflicts of interests result in some form of corruption (such as internal and/or external fraud, bribery, inappropriate governance, nepotism or cronyism), it is prudent however to establish and maintain an effective conflict of interest management programme to ensure that conflicting interests are identified timeously, mitigated where possible and suitably managed.",
        },
        {
          id: 7,
          description: "Managing conflicting interests requires:",
          points: [
            "Mechanisms for the identification of conflicting interests;",
            "Measures for the avoidance of conflict of interests, and where avoidance is not possible, measures for the mitigation of such conflicts of interest;",
            "Measures for the disclosure of conflict of interests;",
            "Policies, procedures and internal controls to facilitate compliance with the measures so stablished; and",
            "Consequence measures for non-compliance.",
          ],
        },
      ],
    },
    {
      id: 2,
      title: "Management of Conflict of Interest",
      numbered: true,
      text: [
        {
          id: 1,
          description: "Remuneration of representatives",
          subtext:
            "All representatives who are authorised to render financial services on behalf of the FSPs are remunerated on a commission basis. The FSPs do not provide any cash incentives to representatives with regard to the submission of any new business. Representatives are not allowed to accept any form of financial reward or payment from clients.",
        },
        {
          id: 2,
          description: "Remuneration of Brokers or Independent Intermediaries",
          subtext:
            "Brokers and Intermediaries are remunerated by way of commission or remuneration in accordance with the regulations. The payment of commission or other fees to Brokers is regulated by the Long-term Insurance Act or Short-term Insurance Act.",
        },
        {
          id: 3,
          description: "Receiving or offering immaterial financial interests",
          subtext:
            "Immaterial financial interest means any financial interest with a determinable monetary value, the aggregate of which does not exceed R1 000 in any calendar year received by a provider, its associate or representative from the same third party or an associate of that third party in that calendar year. For purposes of managing potential conflicts of interest, the FSPs will ensure that any financial interest offered to brokers and representatives will not exceed R1 000 in any calendar year.\nThe FSPs will not offer any financial interest to its representatives, key individuals and/or independent Brokers for: giving preference to a quantity of business to the exclusion of quality to the customer;",
          points: [
            "Giving preference to a specific product supplier or except for authorised developmental, financial inclusion, and transformation objectives;",
            "Cash, cash equivalent, voucher, gift, service, advantage, benefit, discount, domestic or foreign travel, hospitality, accommodation, sponsorship and any other incentive or valuable consideration not mentioned above, including travel and accommodation associated with allowed training for employees in FAIS role;",
            "Any financial interest other than allowable financial interests listed above with a determinable monetary value not exceeding the set thresholds;",
            "Training restricted to a selected group of providers and FAIS representatives on products and legalities thereof; general financial and industry information; specialised technological systems (of a third party) necessary for rendering a financial service.",
            "Sign-on bonus. No person may offer or provide a sign-on bonus to any person, other than a new entrant in the financial services industry, as an incentive to become a Category I Financial Services provider that is authorised or appointed to give advice.\nShould a conflict of interest arise which cannot be avoided, the FSPs will disclose to the client, or Broker the nature and extent of such conflict and any possible steps that may be taken to mitigate the conflict",
          ],
        },
        {
          id: 4,
          description: "Training And Awareness",
          subtext:
            "Guidelines and awareness training shall be provided on an ongoing basis by to all employees, including representatives and key individuals. Other related guidance notes may be made available on the intranet for ease of reference.\nSenior Managers shall ensure that all new and current employees are aware of the Conflict of Interest Management Policy and all training requirements.",
        },
        {
          id: 5,
          description:
            "Reporting Procedure on The Declaration of Conflict of Interest",
          subtext:
            "All employees are required to declare their interests in the prescribed form attached and/or in any other form Workerslife may prescribe.\nAll declarations are to be handled in the strictest confidence in the declaration of conflict of interest/gift register",
        },
        {
          id: 6,
          description: "Consequences Of Non-Adherence",
          subtext:
            "Violation of the Conflict of Interest Policy and procedures described herein will result in disciplinary action.",
        },
      ],
    },
  ],
}

const ConflictOfInterest: React.FC = () => {
  return (
    <Container>
      <h1>Conflict of interests</h1>
      {data.content.map(item => (
        <CollapsibleList key={item.id} title={item.title}>
          {item.text.map((textItem, index) => (
            <FAQText
              key={`textItem-${item.id}.${index}`}
              text={{
                id: textItem.id,
                numbered: item.numbered,
                description: textItem.description,
                subtext: textItem.subtext || "",
                points: textItem.points || [],
              }}
            />
          ))}
        </CollapsibleList>
      ))}
    </Container>
  )
}

export default ConflictOfInterest

const Container = styled.div`
  padding: 0 20px 100px 20px;

  h1 {
    font-family: "PlayfairDisplay";
    font-style: normal;
    font-weight: 800;
    font-size: 86px;
    line-height: 115px;
    text-align: center;
    padding: 0rem 10rem 1rem 10rem;

    @media screen and (max-width: ${props => props.theme.maxBreakpoints.xl}) {
      padding: 0rem 6rem 1rem 6rem;
    }
    @media screen and (max-width: ${props => props.theme.maxBreakpoints.lg}) {
      padding: 0rem 3rem 1rem 3rem;
    }
    @media screen and (max-width: ${props => props.theme.maxBreakpoints.md}) {
      padding: 0rem 0rem 1rem 0rem;
      font-size: 76px;
    }
    @media screen and (max-width: ${props => props.theme.maxBreakpoints.sm}) {
      line-height: 95px;
      font-size: 56px;
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 0 96px 0 58px;
  }
`
