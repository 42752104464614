import React from "react"
import styled from "styled-components"

import { SEO } from "../components"
import { ConflictOfInterest } from "../sections/conflict-of-interest.tsx"

const Disclaimer: React.FC = () => {
  return (
    <PageWrapper>
      <SEO
        title="Conflict of Interest"
        description="Explore Workerslife's commitment to ethical conduct. Learn how we manage conflicts of interest, ensuring fairness and trust in all business dealings."
      />
      <ConflictOfInterest />
    </PageWrapper>
  )
}

export default Disclaimer

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    row-gap: 85px;
  }
`
